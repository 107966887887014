<template>

  <page-header-wrapper>

    <a-card :bordered="false">
      <a-button type="primary" @click="onToggle">
        开启设置
      </a-button>
      <br />
      <br />
      <a-form layout="horizontal">
        <a-form-item
          label="开启全局KeyChip验证"
        >
          <a-switch :checked="keychip" :disabled="disabled" :loading="keychipLoading" @change="keyChipChange"/>
        </a-form-item>
      </a-form>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { getKeyChipVerification, updateKeyChipVerification } from '@/api/arcade'

export default {
  name: 'ArcadeConfig',
  data () {
    return {
      disabled: true,
      keychip: true,
      keychipLoading: true
    }
  },
  mounted () {
    getKeyChipVerification().then(res => {
      this.keychip = res
      this.keychipLoading = false
    })
  },
  methods: {
    onToggle () {
      this.disabled = !this.disabled
    },
    keyChipChange (ev) {
      this.keychip = ev
      this.keychipLoading = true
      updateKeyChipVerification({ status: ev }).then((res) => {
        this.keychip = res
        this.$message.success('更新成功')
      }).finally(() => {
        this.keychipLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
